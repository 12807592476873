.match-card {
  flex: 1 1 calc(25% - 32px); /* Allow about 4 cards per row, considering the gap */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  max-width: 450px; /* Keep your existing max-width */
  margin: 15px; /* Use your existing margin */
  height: 310px; /* Retain your existing height */
  background-color: #1f2937;
  padding: 10px;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.tournament-short-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 12px;
  color: #ea2a2a;
}

.match-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 0px;
}

.team-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px;
}

.team img {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

.team-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: #f3f4f6;
  word-wrap: break-word;
  text-align: center;
  max-width: 140px;
  height: 50px;
  padding: 5px;
  margin: 5px 0;
}

.team-rank {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ea2a2a;
  margin-top: 5px;
  margin-bottom: 5px;
}

.match-header h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #f3f4f6;
}

.score {
  font-size: 2rem;
  font-weight: bold;
  color: #f3f4f6;
  text-align: center;
  margin-top: 20px;
  word-spacing: 15px;
}

.score-time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #ea2a2a;
  margin-top: 5px;
}

.pi-stopwatch {
  font-size: 1.4rem;
  margin-right: 5px;
}

/* Button styles */
.action-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0;
  padding: 0 10px;
}

.action-buttons button {
  font-size: 0.75rem; /* Reduced font size */
  font-weight: 600;
  text-transform: uppercase;
  width: auto;
  flex: 1;
  margin: 0 5px;
  padding: 7px 10px; /* Reduced padding */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 6px;
  border: none;
  display: flex; /* Flexbox to align icon and text */
  align-items: center; /* Vertically centers icon and text */
  justify-content: center; /* Centers both icon and text horizontally */
}

/* Specific button colors */
.action-buttons .spectate-btn {
  background-color: #ea2a2a;
  color: white;
}

.action-buttons .spectate-btn:hover {
  background-color: #c81f1f;
}

.action-buttons .opgg-btn {
  background-color: transparent;
  color: #4f46e5;
  border: 1px solid #4f46e5;
}

.action-buttons .opgg-btn:hover {
  background-color: #4f46e5;
  color: white;
}

.action-buttons .more-info-btn {
  background-color: #6b7280;
  color: white;
}

.action-buttons .more-info-btn:hover {
  background-color: #4b5563;
}

.action-buttons .more-info-btn i {
  font-size: 1rem; /* Icon size */
  margin-right: 5px;
}

/* Adjust padding in the overlay */
.p-overlaypanel-content {
  padding: 5px !important; /* Halved the padding */
}

/* Make more-info-panel fill available space */
.more-info-panel {
  width: 100%;
}

/* Bans section layout */
.banned-champions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.bans-left {
  display: flex;
  justify-content: flex-start;
}

.bans-right {
  display: flex;
  justify-content: flex-end;
}

.banned-champion-image {
  width: 20px;
  height: 20px;
  margin: 2px; /* Reduced margin */
}

/* Participant section layout */
.player-info {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Added small gap between left and right teams */
  min-height: 100%; /* Ensure height consistency */
}

.team-left, .team-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%; /* Ensuring both sides have equal height */
}

.team-left {
  align-items: flex-start; /* Align to left */
}

.team-right {
  align-items: flex-end; /* Align to right */
}

.player {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Halved the margin */
}

.participant-champion-image {
  width: 30px;
  height: 30px;
  margin-top: 2px; /* Only top margin of 2px */
}

/* Left side player: Champion image on left, IGN on right */
.team-left .participant-champion-image {
  margin-top: 2px;
  margin-right: 10px; /* Added 10px right margin */
}

.team-left .player p {
  color: #f3f4f6;
  font-size: 0.85rem; /* Reduced font size */
  margin: 0; /* Removed left margin */
}

/* Right side player: IGN on left, Champion image on right */
.team-right .player {
  justify-content: flex-end; /* Align content to the right */
}

.team-right .player p {
  color: #f3f4f6;
  font-size: 0.85rem; /* Reduced font size */
  margin: 0; /* Removed right margin */
  order: 1; /* Ensure the IGN comes first */
}

.team-right .participant-champion-image {
  margin-left: 10px; /* Space between IGN and image */
  margin-right: 0;
  order: 2; /* Ensure image comes second */
}
