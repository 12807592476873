body {
  background-color: #000000; /* Black background */
  color: #ffffff;            /* White text for better readability */
  font-family: Arial, sans-serif; /* Set a default font if you want */
}

.app {
  font-family: Arial, sans-serif;
  min-height: 100vh; /* Ensures the background color covers the entire screen */
}

.main-content {
  padding-top: 1.5625rem; /* To create space for the NavBar */
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-navbar .pi {
  color: #ea2a2a !important;
}

.player-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start; /* Align items to the left */
}

.player-card-container .p-card {
  flex: 1 1 calc(33.33% - 1rem);
  max-width: 450px;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .player-card-container .p-card {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (max-width: 600px) {
  .player-card-container .p-card {
    flex: 1 1 calc(100% - 1rem);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #282828; /* Same as NavBar background */
  padding: 1rem;
  text-align: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 15rem; /* Increased gap to space out icons more */
}

.footer .pi {
  color: #ea2a2a; /* Same color as NavBar icons */
  font-size: 1rem; /* Reduced size of the icons */
  cursor: pointer; /* Makes the icon look clickable */
}